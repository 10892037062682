@import url("font/pretendard/pretendardvariable-dynamic-subset.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overscroll-behavior-y: contain;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue",
    "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  height: calc(var(--vh, 1vh) * 100);
  background-color: white;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.ellipsis-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; /* stylelint-disable */
  -webkit-line-clamp: 3; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.disable-scroll {
  height: 100%;
  min-height: 100%;
  overflow: hidden !important;
  touch-action: none;
}

.slide-image {
  margin-top: 74px;
}

@media (min-width: 640px) {
  .slide-image {
    margin-top: 25%;
  }
}

/* safari 환경 */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .slide-image {
      margin-top: 54px;
    }
  }
}

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl;
}

/* 비교 화면 swiper 스타일 start */
.swiper-pagination-bullet {
  width: 0.75rem !important;
  height: 0.75rem !important;
  background: white !important;
  opacity: 0.8 !important;
}

.swiper-pagination-bullet-active {
  background-color: #ef6363 !important;
}

/* 바텀 시트 스타일 */
[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]::after {
  z-index: 49 !important;
}

[data-rsbs-is-blocking="true"] [data-rsbs-overlay],
[data-rsbs-is-blocking="false"] [data-rsbs-overlay] {
  max-width: 36rem;
  margin: auto;
  z-index: 30;
}

[data-rsbs-header] {
  box-shadow: 0 0 0 !important;
}

/* 사용자 피드백(만족도 평가) 바텀 시트 스타일 */
.satisfaction [role="dialog"] {
  background: #ffffff;
  box-shadow: -1px 0px 16px rgba(0, 0, 0, 0.24) !important;
  border-radius: 24px 24px 0px 0px;
}

.satisfaction [data-rsbs-scroll] {
  overflow: hidden;
}

.satisfaction [data-rsbs-backdrop] {
  background-color: unset;
  cursor: pointer !important;
}

.satisfaction [data-rsbs-header]:before {
  height: 0;
}

.satisfaction [data-rsbs-header] {
  display: flex;
  align-items: center;
  padding: 0 20px 0;
  font-weight: 700;
  font-size: 17px;
  color: #1f2024;
  letter-spacing: -0.02em;
  line-height: 150%;
  cursor: default;
}

.satisfaction [data-rsbs-footer] {
  background: #ffffff;
  box-shadow: 0 0 0 !important;
  padding: 0;
}

/* 체크박스 포뮬러 디자인 */
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  cursor: pointer;
  padding-left: 32px;
  background-repeat: no-repeat;
  background-image: url("/icons/control-checkbox-nonselect.svg");
}

input[type="checkbox"]:checked + label {
  background-image: url("/icons/control-checkbox-select.svg");
}

input[type="checkbox"]:disabled + label {
  background-image: url("/icons/control-checkbox-nonselect.svg");
}

.text-over-dot::after {
  width: 4px;
  height: 4px;
  clear: both;
  content: "";
  background-color: #ed394b;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -5px;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.height-realscreen {
  height: calc(var(--vh, 1vh) * 100);
}

.height-headless-area {
  height: calc(var(--vh, 1vh) * 100 - 56px);
}
